

/** 手机版公众号 -- 有优惠券的会员中心 */
import 'amfe-flexible'
import { defineComponent, onMounted, reactive, computed } from 'vue'
import { useRoute } from 'vue-router';
import { useReflectiveInjector, Type } from '@tanbo/vue-di-plugin'
import {
  IndexService,
  WxWebData,
  interestList,
  CouponResponseData,
  CouponList
} from './index.service'
import { UserService } from '@/services/user.service'
import { ToastService } from '@/services/toast.service'
import { ConfigService } from '@/services/config.service';
import { CookieService } from '@/services/cookie.service';

export default defineComponent({
  name: 'CouponMemberCenter',
  setup() {
    const route = useRoute()
    const injector = useReflectiveInjector([IndexService])
    const service = injector.get(IndexService)
    const user = injector.get(UserService)
    const config = injector.get(ConfigService as Type<ConfigService>)
    const cookie = injector.get(CookieService)
    const toast = injector.get(ToastService)

    const show_from = route.query.showfrom || '26' // 区分微信公众号不同消息模板来源
    const defalutAvatar = require('./_images/default_avater.png') // 默认头像

    const viewModel = reactive({
      code: route.query.code as string,
      payIdx: 0, /** 当前支付套餐 */
      paying: false, /** 支付状态 */
      render: false, /** 是否渲染 */
      renewChecked: true, /** 是否续费 */
      payModuleShow: false, /** 支付模块是否显示 */
      useCouponStatus: true, /** 是否使用优惠券 */
      noCouponsettings: [], /** 不使用优惠券套餐列表 */
      useCouponsettings: [], /** 使用优惠券套餐列表 */
      couponInfo: {} as CouponResponseData, /** 优惠券信息 */
      userMark: service.getUserMark(), // 用户信息集合（用于判断是否登录）
      isVip: user.isVip,
      nickname: user.nickname,
      avatar: defalutAvatar, /** 头像 */
      isDiamondVIP: user.isDiamondVIP,
      normalVIP: user.normalVIP,
      isContinuous: user.isContinuous,
      expireDate: user.memberDateText()
    })

    /** 根据条件模板使用某个套餐 */
    const settings = computed(() => viewModel.noCouponsettings)

    /** 当前支付价格集合（兼容优惠券价格计算） */
    const currentSettings = computed(() => {
      return viewModel.useCouponStatus ? viewModel.useCouponsettings : viewModel.noCouponsettings
    })

    /** 非续费会员用户 */
    const noContinuousMember = computed(() => !viewModel.isContinuous && viewModel.normalVIP)

    /** 当前套餐价格 */
    const currentPrice = computed(() => {
      return currentSettings.value[viewModel.payIdx]?.page_detail.current_fee || 0
    })

    /** 控制续费多选框的显示 */
    const controlRenewShow = false

    /** 当前套餐id */
    const currentSetttingId = computed(() => currentSettings.value[viewModel.payIdx].product_id)

    /** 控制钻石会员文案展示 */
    const controlDiamondTextShow = computed(() => currentSettings.value[viewModel.payIdx]?.page_detail?.is_diamond)

    /** 头部描述定制文案 */
    const headText = computed(() => {
      const textArr: any[] = [
        { judge: viewModel.isDiamondVIP, text: '您已升级为钻石会员，不限时享受高级特权！' }, // 钻石会员
        { judge: !viewModel.isContinuous && viewModel.normalVIP, text: `${viewModel.expireDate}` }, // 非续费会员
        { judge: viewModel.isContinuous && viewModel.normalVIP, text: '您已成为会员，享受会员专属特权！' }, // 续费会员
        { judge: !viewModel.isVip, text: '开通会员，享受高级特权' }, // 已登录非会员
        { judge: !viewModel.userMark.open_id && !viewModel.userMark.token, text: '登录获取最新优惠信息！' } // 未登录
      ]

      return textArr.find(l => l.judge).text
    })

    /** 当前优惠券价格 */
    const couponPrice = computed(() => {
      const id = viewModel.useCouponsettings[viewModel.payIdx]?.product_id // 当前套餐id
      const item:CouponList = (viewModel.couponInfo?.coupon_list || []).find(c => c.coupon_product_id === id)

      return item?.page_detail?.discount || 0
    })

    onMounted(async () => {
      if (viewModel.code) {
        await loginAction()
      } else {
        service.silentWxAuth()
        return
      }

      viewModel.render = true

      service.report({ show_from })
      
      init()
    })

    /** 订阅用户信息变化 */
    user.onUserInfoChanged.subscribe(() => {
      const box = ['isVip', 'nickname', 'avatar', 'isDiamondVIP', 'normalVIP', 'isContinuous']
      box.forEach(e => (viewModel[e] = user[e]))

      viewModel.expireDate = user.memberDateText()

      viewModel.payModuleShow = !user.isVip // 更新支付模块展示
    })

    /**
     * @description 初始化
     */
    async function init() {
      await requestUserInfo()
      loadPayConfig()
    }

    /**
     * @description 加载支付配置
     */
    async function loadPayConfig() {
      document.title = `${config.getTitle}会员中心`
      
      if (viewModel.payModuleShow) {
        await initJsSdkConfig()
        await requestCouponConfig()
        getPayInfo()

        document.title = `购买${config.getTitle}会员`
      }
    }

    /**
     * @description 登录
     */
    async function loginAction () {
      const params = {
        code: viewModel.code // 微信code
      }

      await service.requestLogin(params).then(res => {
        viewModel.userMark.open_id = res?.user_info?.open_id
        viewModel.userMark.token = res?.token_info?.token

        service.setUserMark(viewModel.userMark)
      }).catch(() => {
        toast.showToast({ message: '登录失败，请稍后再试' })
      })
    }

    /**
     * @description 获取用户信息
     */
    async function requestUserInfo () {
      await service.requestUserInfo({ ...commonRequestParams() }).catch(() => {
        service.clearCookie()
        service.silentWxAuth()
      })
    }

    /**
     * @description 获取优惠券配置
     */
    function requestCouponConfig() {
      const params = {
        group_coupon_conf_id: config.getScenePackageId
      }

      return service.requestCouponConfig(params).then(res => {
        viewModel.couponInfo = res
      })
    }

    /**
     * @description 获取套餐列表
     * @param show 活动套餐类型
     */
    function getPayInfo() {
      const { coupon_group = '', no_coupon_group = '' } = viewModel.couponInfo
      const params = {
        ...commonRequestParams(),
        group_ids: [coupon_group, no_coupon_group],
        is_continuous_scene: false
      }

      return service.requestPaySettings(params).then(res => {
        viewModel.useCouponsettings = res?.pay_groups[0]?.pay_packages
        viewModel.noCouponsettings = res?.pay_groups[1]?.pay_packages

        matchDefaultPayIdx(res?.pay_groups[0]?.default_product_id)
      })
    }

    /**
     * @description 匹配默认支付下标
     */
    function matchDefaultPayIdx (defaultProductId: string) {
      const selPayIdx = currentSettings.value.findIndex(e => e.product_id === defaultProductId)

      if (selPayIdx > -1) {
        viewModel.payIdx = selPayIdx
      }
    }

    /**
     * @description 根据当前所选套餐的续费类型执行不同的支付操作
     */
    function payAction() {
      noRenewPayAction()
    }

    /**
     * @description 非续费支付
     */
    function noRenewPayAction() {
      if (viewModel.paying) {
        return
      }

      viewModel.paying = true

      const params = {
        ...commonRequestParams(),
        product_id: currentSetttingId.value,
        openid: user.userInfo?.user_info?.wx_info?.wx_web_open_id,
        payapp: cookie.productId,
        client_extra: {
          ...service.client_extra,
          show_from /** 会员页展示来源 */
        }
      }

      service.requestPay(params).then(res => {
        chooseWxPay(res?.wx_resp)
      }).catch(() => handlePayFail())
    }

    /**
     * @description 处理下单失败
     */
    function handlePayFail () {
      viewModel.paying = false
      toast.showToast({ message: '下单异常,请重试!' })
    }

    /**
     * @description 初始化微信jssdk配置
     */
    async function initJsSdkConfig () {
      const params = {
        ...commonRequestParams(),
        url: location.href
      }

      await service.requestJsSdkConfig(params).then(res => {
        service.wxConfigInit(res)
      })
    }

    /**
     * @description 调起微信支付
     */
    function chooseWxPay (payData: WxWebData) {
      service.chooseWxPay(
        payData,
        () => {
          viewModel.paying = false
          toast.showToast({ message: '支付成功!', onClose: () => init() })
        },
        () => {
          viewModel.paying = false
        },
        () => {
          viewModel.paying = false
        }
      )
    }

    /**
     * @description 请求公共参数
     */
    function commonRequestParams () {
      return {
        common: {
          open_id: viewModel.userMark.open_id,
          token: viewModel.userMark.token,
        }
      }
    }

    /**
     * @description 图片加载失败使用默认头像
     */
    function errorUseDefaultImage () {
      viewModel.avatar = defalutAvatar
    }

    /**
     * @description 会员续费
     */
    function MemberRenewAction () {
      if (viewModel.payModuleShow) return
      viewModel.payModuleShow = true
      loadPayConfig()
    }

    /**
     * @description 切换支付套餐
     */
    function switchPaySetting(idx: number) {
      viewModel.payIdx = idx
    }

    /**
     * @description 切换续费状态
     */
    function toggleRenewStatus () {
      viewModel.renewChecked = !viewModel.renewChecked
      getPayInfo()
    }

    /**
     * @description 打开协议
     */
    function openProtocol() {
      window.open('//')
    }

    /**
     * @description 使用优惠券操作
     */
    function useCouponAction () {
      viewModel.useCouponStatus = !viewModel.useCouponStatus
    }

    return {
      /** data&computed */
      viewModel,
      headText,
      settings,
      interestList,
      currentPrice,
      couponPrice,
      controlRenewShow,
      noContinuousMember,
      controlDiamondTextShow,
      /** methods */
      payAction,
      openProtocol,
      useCouponAction,
      MemberRenewAction,
      switchPaySetting,
      toggleRenewStatus,
      errorUseDefaultImage,
    }
  }
})
